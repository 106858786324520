import React from 'react';

import { Layout } from '../components/shared/Layout';

const AboutPage: React.FC = () => {
  return (
    <Layout padded={true}>
      <div style={{ maxWidth: '500px', padding: '10px', margin: '0 auto', marginTop: '5rem' }}>
        <h1 style={{ textAlign: 'center' }}>Maak je het mee? </h1>
        <h2 style={{ textAlign: 'center', marginBottom: '3rem', lineHeight: 1.5 }}>
          Meld je dan meteen aan voor de Haagse Makers Week 2020!
        </h2>
        <p>
          De Haagse Makers Week is een unconference over do-it-together in Den Haag. Dit betekent dat jij de inhoud van
          het programma bepaalt!
        </p>
        <p>
          Wil jij aan anderen laten zien wat je maakt, hoe je het maakt, zou willen maken, wat jouw inspiratie is? Meld
          je aan met een activiteit en we zetten je op het programma op de website.{' '}
        </p>
        <h3>Aanmelden</h3>
        <p>
          Aanmelden gaat het makkelijkst door een bericht te plaatsen op het{' '}
          <a href="https://community.haagsemakers.nl" target="_blank">
            Haagse Makers Forum
          </a>
          . Als je nog geen Haagse Makers account hebt dan kun je er eerst 1 aanmaken.
        </p>
        <p>Om een activiteit aan te maken zijn in ieder geval de volgende gegevens nodig:</p>
        <ul>
          <li>Titel</li>
          <li>Datum en tijd</li>
          <li>Locatie</li>
          <li>Beschrijving</li>
          <li>Foto</li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <a
            className="btn"
            style={{ fontSize: '1.25rem', padding: '15px 40px', margin: '2rem 0' }}
            href="https://community.haagsemakers.nl"
          >
            Meld je aan via Haagse Makers Community &rarr;
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
